//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Null from "@/components/Null";
import { ctrlCourse } from "@/controller";
import { Button, Image, Card } from "element-ui";
export default {
	components: {
		[Button.name]: Button,
		[Image.name]: Image,
		[Card.name]: Card,
		Null,
	},
	data() {
		return {
			courseList: [],
		};
	},
	created() {
		this.init();
	},
	methods: {
		async init() {
			await this.getCourseList();
		},
		async getCourseList() {
			this.courseList = await ctrlCourse.getCourseList();
			console.log(this.courseList);
		},
		//前往課程詳情頁
		goCourseDetail(item) {
			if (this.Common.isExist(item.playbackInfo)) {
				return this.$router.push(`/course/detail/${item.playbackInfo.ptNbr}?chpdNbr=${item.playbackInfo.chpdNbr}`);
			}
			this.$router.push(`/course/detail/${item.ptNbr}`);
		},
	},
};
